import { useState } from 'react';
import { ThumbsDownIcon, ThumbsUpIcon } from 'lucide-react';
import T from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import Button from '@rewardopl/react-ui/button';
import Dialog from '@rewardopl/react-ui/dialog';
import TextareaField from '@rewardopl/react-ui/textarea_field';

import colors from '@rewardopl/styles/colors';

import {
  Wrapper,
  Image,
  Description,
  Question,
  ThumbsWrapper,
  Input,
  Label,
  Text,
} from './dialog.styles';

import popperIcon from '../../../../static/popper.svg';

import type { Transaction } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

type TransactionsMonitorDialogProps = {
  onDismiss: () => void;
  transaction: Transaction;
};

export default function TransactionsMonitorDialog({
  onDismiss,
  transaction,
}: TransactionsMonitorDialogProps) {
  const [rating, setRating] = useState<number>();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRating(Number(event.target.value));
  }

  const action = '/api/feedback';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    await fetch(action, {
      method: 'POST',
      body: new FormData(form),
    });

    onDismiss();
  }

  return (
    <Dialog isOpen onDismiss={onDismiss} title="Awesome!">
      <Wrapper>
        <Image src={popperIcon} alt="" width="256" height="256" />
        <Description>
          <T amount={transaction.amount}>{`You have earned {amount} stamp${
            transaction.amount === 1 ? '' : 's'
          }!`}</T>
        </Description>
        <Question>
          <T>How was your experience?</T>
        </Question>
        <form action={action} method="POST">
          <input
            type="hidden"
            name="card_subscription_id"
            value={transaction.card_subscription_id}
          />
          <input type="hidden" name="transaction_id" value={transaction._id} />
          <ThumbsWrapper>
            <Input type="radio" name="rating" onChange={onChange} id="bad" value="0" />
            <Label htmlFor="bad">
              <Text>Bad</Text>
              <ThumbsDownIcon
                fill={rating === 0 ? colors['error-background'] : 'transparent'}
                size={64}
                stroke={colors['error-dark']}
                strokeWidth={1}
              />
            </Label>
            <Input type="radio" name="rating" onChange={onChange} id="good" value="100" />
            <Label htmlFor="good">
              <Text>Good</Text>
              <ThumbsUpIcon
                fill={rating === 100 ? colors['success-background'] : 'transparent'}
                size={64}
                stroke={colors['success-dark']}
                strokeWidth={1}
              />
            </Label>
          </ThumbsWrapper>
          {rating === 0 ? (
            <TextareaField name="comment" label="Please tell us how we can improve." />
          ) : null}
          {rating !== undefined ? (
            <AsyncButton
              as={Button}
              className="primary"
              onClick={onClick}
              pendingConfig={pendingConfig}
              type="submit"
            >
              <T>Submit</T>
            </AsyncButton>
          ) : null}
        </form>
      </Wrapper>
    </Dialog>
  );
}
