import { Routes, Route, Navigate, resolvePath, useLocation } from 'react-router-dom';

import RewardPage from './reward';

export default function RewardsPage() {
  const location = useLocation();

  return (
    <Routes>
      <Route path=":reward_id/*" element={<RewardPage />} />
      <Route path="*" element={<Navigate to={resolvePath('..', location.pathname)} />} />
    </Routes>
  );
}
