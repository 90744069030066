import { useAtomValue } from 'jotai';

import { getIsRegistered } from '@rewardopl/utils/user';

import { currentUserState } from '../store';

export default function useIsRegistered(): boolean {
  const currentUser = useAtomValue(currentUserState);

  return getIsRegistered(currentUser);
}
