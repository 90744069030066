import { Navigate, useLocation } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import NoAccessPage from './pages/no_access';

import { maybeCurrentUserState } from '../store';

import type { User } from '@rewardopl/types';

type AuthFn = (user: User | null) => boolean;

type AuthGateProps = {
  anyOf: AuthFn | AuthFn[];
  children: React.ReactElement;
};

export default function AuthGate({ anyOf, children }: AuthGateProps) {
  const location = useLocation();
  const currentUser = useAtomValue(maybeCurrentUserState);

  const isAuthorized = Array.isArray(anyOf)
    ? anyOf.some((fn) => fn(currentUser))
    : anyOf(currentUser);

  if (isAuthorized) {
    return children;
  }

  const { pathname, search } = location;

  const { returnPath = '/' } = (location.state || {}) as { returnPath?: string };

  const toPathname = (() => {
    if (currentUser) {
      return returnPath;
    }

    if (pathname === '/') {
      return '/onboarding';
    }

    return null;
  })();

  if (toPathname) {
    return <Navigate to={toPathname} replace />;
  }

  const state = { returnPath: pathname + search };

  return <NoAccessPage state={state} />;
}
