import { ContentWrapper } from './contact_us.styles';

export default function ContactUs() {
  return (
    <ContentWrapper>
      <p>
        Salad Story S.A.
        <br />
        Aleje Jerozolimskie 179 (Blue Office II poziom 0)
        <br />
        02-222 Warszawa
      </p>

      <p>NIP 5213399767</p>

      <h3>Dane kontaktowe</h3>

      <p>
        <strong>Dział obsługi klienta:</strong>
        <br />
        Adres email: <a href="mailto:kontakt@saladstory.com">kontakt@saladstory.com</a>
      </p>
    </ContentWrapper>
  );
}
