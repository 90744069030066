import styled from 'styled-components';

import { Wrapper as AcceptTermsAndConditionsWrapper } from '../accept_terms_and_conditions/index.styles';

export const PageWrapper = styled(AcceptTermsAndConditionsWrapper)`
  background: var(--background);
  padding: 0;
`;

export const ContentWrapper = styled.div`
  max-width: 400px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: calc(var(--gap) * 2) var(--gap);
  text-align: center;
`;

export const Icon = styled.div`
  margin: 2em auto 1em;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.p`
  margin-top: 1em;
`;

export const Wrapper = styled.div`
  margin: 2em 0 3em;
`;

export const Hint = styled.div`
  margin: 0 0 var(--gap);
  font-size: 16px;
  font-weight: 700;
`;
