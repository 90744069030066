import useLocationParams from './useLocationParams';

import type { NavigateOptions } from 'react-router-dom';

export default function useLocationParam(name: string, options?: NavigateOptions) {
  const [params, onChange] = useLocationParams(options);

  const param = name in params ? params[name] : undefined;

  function onParamChange(nextParam: string | number | boolean | null) {
    onChange({
      ...params,
      [name]: nextParam,
    });
  }

  return [param, onParamChange] as const;
}
