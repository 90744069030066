import { useAtomValue } from 'jotai';
import { useTranslation } from '@wojtekmaj/react-t';

import { Wrapper, Link } from './header.styles';

import SaladStoryLogo from '../../common/saladstory_logo';
import Avatar from '../../common/avatar';

import { currentUserState } from '../../../store';

export default function Header() {
  const currentUser = useAtomValue(currentUserState);
  const profileString = useTranslation('Profile');

  return (
    <Wrapper>
      <SaladStoryLogo width={241} />
      <Link aria-label={profileString} to="/profile">
        <Avatar user={currentUser} size={48} />
      </Link>
    </Wrapper>
  );
}
