import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    vertical-align: top;
  }
`;

export const Count = styled.span``;
