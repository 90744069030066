import styled from 'styled-components';

import { Wrapper as FieldWrapper, Hint } from '@rewardopl/react-ui/field/index.styles';

export { Hint };

export const OptionsWrapper = styled.div`
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
`;

export const MonthFieldWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  gap: var(--gap);

  ${FieldWrapper} {
    flex-grow: 1;
  }
`;
