import { HeartIcon } from 'lucide-react';

import { Wrapper, Count } from './like_count.styles';

type LikeCountProps = {
  count?: number;
};

export default function LikeCount({ count = 0 }: LikeCountProps) {
  return (
    <Wrapper>
      <HeartIcon fill="var(--text)" color="var(--text)" size={16} />
      <Count>{count}</Count>
    </Wrapper>
  );
}
