import { Wrapper, Links, Copyright } from './footer.styles';

import TermsAndConditionsLink from './terms_and_conditions_link';
import PrivacyPolicyLink from './privacy_policy_link';
import ContactUsLink from './contact_us_link';

export default function Footer() {
  return (
    <Wrapper>
      <Links>
        <TermsAndConditionsLink />
        <PrivacyPolicyLink />
        <ContactUsLink />
      </Links>
      <Copyright>© Salad Story S.A. 2024</Copyright>
    </Wrapper>
  );
}
