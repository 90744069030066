import styled, { css } from 'styled-components';

import { linkStyle } from '../root.styles';

export const Button = styled.button<{ showLabel?: boolean }>`
  ${linkStyle}
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  color: var(--text);
  gap: 8px;

  ${(props) =>
    !props.showLabel &&
    css`
      width: 44px;
      height: 44px;
      justify-content: center;
      margin: -12px;
    `}
`;

export const Text = styled.span``;
