import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  cardSubscriptionsState,
  currentUserIdState,
  pendingCouponRefreshesState,
  pendingRewardRefreshesState,
  vendorMessagesState,
} from '../../../store';

import type { SetStateAction } from 'jotai';
import type { Card, CardSubscription, Message } from '@rewardopl/types';
import type { BaseDocument } from '@rewardopl/types/helpers/base';

type Action = 'append' | 'delete' | 'prepend' | 'update';

type BasePayload<T extends BaseDocument = BaseDocument> = {
  type: string;
  action: Omit<Action, 'delete'>;
  data: T;
};

type DeletePayload<T extends BaseDocument = BaseDocument> = {
  type: string;
  action: 'delete';
  data: Pick<T, '_id'>;
};

type RefreshPayload<T extends BaseDocument = BaseDocument> = {
  type: string;
  action: 'refresh';
  data: T['_id'];
};

type Payload<T extends BaseDocument = BaseDocument> =
  | BasePayload<T>
  | DeletePayload<T>
  | RefreshPayload<T>;

function isDeletePayload<T extends BaseDocument>(payload: Payload<T>): payload is DeletePayload<T> {
  return payload.action === 'delete';
}

function isRefreshPayload(payload: Payload): payload is RefreshPayload {
  return payload.action === 'refresh';
}

function handleAction<T extends BaseDocument>(
  setter: (nextValueOrSetter: SetStateAction<T[]>) => void,
  payload: Exclude<Payload<T>, RefreshPayload<T>>,
) {
  if (isDeletePayload(payload)) {
    const { data: nextData } = payload;

    setter((prevData) => prevData.filter((prevItem) => prevItem._id !== nextData._id));
    return;
  }

  const { action, data: nextData } = payload;

  switch (action) {
    case 'append':
      setter((prevData) => [...prevData, nextData]);
      break;
    case 'prepend':
      setter((prevData) => [nextData, ...prevData]);
      break;
    case 'update':
      setter((prevData) =>
        prevData.map((prevItem) => (prevItem._id === nextData._id ? nextData : prevItem)),
      );
      break;
    default:
      console.error(`Unknown payload action type: ${action satisfies Omit<Action, 'delete'>}`);
  }
}

export default function WebsocketHandler() {
  const currentUserId = useAtomValue(currentUserIdState);
  const setCardSubscriptions = useSetAtom(cardSubscriptionsState);
  const setMessages = useSetAtom(vendorMessagesState);
  const setPendingCouponRefreshesState = useSetAtom(pendingCouponRefreshesState);
  const setPendingRewardRefreshesState = useSetAtom(pendingRewardRefreshesState);

  useEffect(() => {
    let ws: WebSocket;

    function setupWebSocket() {
      ws = new WebSocket(`wss://${window.location.host}/ws?user_id=${currentUserId}`);

      ws.addEventListener('message', (event) => {
        const { data: rawPayload } = event;

        const payload = JSON.parse(rawPayload) as Payload;

        if (isRefreshPayload(payload)) {
          switch (payload.type) {
            case 'coupon':
              setPendingCouponRefreshesState((prev) => [
                ...prev,
                (payload as RefreshPayload<Card>).data,
              ]);
              break;
            case 'reward':
              setPendingRewardRefreshesState((prev) => [
                ...prev,
                (payload as RefreshPayload<Card>).data,
              ]);
              break;
            default:
              console.error(`Unhandled refresh payload type: ${payload.type}`);
          }
        } else {
          switch (payload.type) {
            case 'card_subscription':
              handleAction(
                setCardSubscriptions,
                payload as Exclude<Payload<CardSubscription>, RefreshPayload>,
              );
              break;
            case 'message':
              handleAction(setMessages, payload as Exclude<Payload<Message>, RefreshPayload>);
              break;
            default:
              console.error(`Unhandled payload type: ${payload.type}`);
          }
        }
      });

      ws.addEventListener('close', (event) => {
        if (!event.wasClean) {
          setTimeout(() => {
            setupWebSocket();
          }, 1000);
        }
      });
    }

    setupWebSocket();

    return () => {
      if (!ws) {
        return;
      }

      ws.close();
    };
  }, [
    currentUserId,
    setCardSubscriptions,
    setMessages,
    setPendingCouponRefreshesState,
    setPendingRewardRefreshesState,
  ]);

  return null;
}
