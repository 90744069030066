import { Wrapper } from './nav.styles';

import RegisterBanner from './register_banner';
import Menu from './menu';

export default function Nav() {
  return (
    <Wrapper>
      <RegisterBanner />
      <Menu />
    </Wrapper>
  );
}
