import { Suspense } from 'react';
import { useAtomValue } from 'jotai';
import T from '@wojtekmaj/react-t';

import { Wrapper, Heading, List, Text } from './index.styles';

import LoadingText from '../../../../../../../loading_text';
import Items from './items';

import useVendorProductsRefresh from '../../../../../../../../hooks/useVendorProductsRefresh';

import { placeProductsQuery } from '../../../../../../../../store';

import type { Place } from '@rewardopl/types';

type ProductsContentProps = {
  placeId: Place['_id'];
};

function ProductsContent({ placeId }: ProductsContentProps) {
  const products = useAtomValue(placeProductsQuery(placeId));

  useVendorProductsRefresh();

  return products.length ? (
    <List>
      <Items placeId={placeId} />
    </List>
  ) : (
    <Text>
      <T>No available items</T>
    </Text>
  );
}

type ProductsProps = {
  placeId: Place['_id'];
};

export default function Products({ placeId }: ProductsProps) {
  return (
    <Wrapper>
      <Heading>
        <T>Available products</T>
      </Heading>
      <Suspense fallback={<LoadingText />}>
        <ProductsContent placeId={placeId} />
      </Suspense>
    </Wrapper>
  );
}
