import styled from 'styled-components';

import { visuallyHidden } from '@rewardopl/react-ui/visually_hidden/index.styles';

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Item = styled.div`
  position: relative;
  padding-left: 24px;
  line-height: 1.5;

  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    vertical-align: top;
    left: 0;
    top: 3px;
  }
`;

export const Label = styled.p`
  ${visuallyHidden}
`;

export const Value = styled.p`
  margin: 0;
`;
