import styled from 'styled-components';

export default styled.span`
  display: inline-block;
  height: 24px;
  margin: -4px 0;
  padding: 3px 10px;
  border: 1px solid var(--stroke);
  border-radius: var(--border-radius-button);
  font: inherit;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
`;
