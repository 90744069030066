import styled from 'styled-components';
import { Link as CommonLink } from 'react-router-dom';

export const ImageAndContentWrapper = styled.div`
  margin-top: calc(var(--gap) * -1);
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 400px;
  display: block;
  aspect-ratio: 256 / 171;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin-top: -24px;
  padding: var(--gap) 12px;
  background: var(--background);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  z-index: 1;

  @media screen and (min-width: 769px) {
    padding: var(--gap);
  }
`;

export const NameWrapper = styled.div``;

export const Name = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  white-space: pre-wrap;
`;

export const Link = styled(CommonLink)`
  display: inline-block;
  margin-bottom: 16px;
  color: var(--secondary);
  font-weight: 500;
`;
