import { Logo as LogoElement } from './logo.styles';

import logoRewardo from '../../static/logo-rewardo.svg';

type LogoRewardoProps = {
  borderWidth?: number;
  size?: number;
};

export default function LogoRewardo({ borderWidth = 4, size = 50 }: LogoRewardoProps) {
  return (
    <LogoElement
      borderWidth={borderWidth}
      width={size}
      height={size}
      src={logoRewardo}
      alt="Rewardo"
    />
  );
}
