import styled from 'styled-components';

import Section from '../../../../../../section.styles';

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 400px;
  display: block;
  aspect-ratio: 256 / 171;
  object-fit: cover;
`;

export const ContentWrapper = styled(Section)``;

export const Description = styled.p`
  white-space: pre-wrap;
`;
