import { Link, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { HeaderAndFormWrapper, PageWrapper, Main, FormWrapper } from '../login/index.styles';

import Heading from '../../heading';
import Header from '../../header';
import SendLinkForm from './send_link_form';
import PasswordResetForm from './password_reset_form';
import Footer from '../../footer';

import useLocationParams from '../../../hooks/useLocationParams';

import type { User } from '@rewardopl/types';

export default function PasswordResetPage() {
  const location = useLocation();
  const [params] = useLocationParams() as [{ id?: User['_id']; token?: string }, () => void];

  const { id, token } = params;

  const hasParams = id && token;

  return (
    <PageWrapper>
      <HeaderAndFormWrapper>
        <Header />
        <FormWrapper>
          <Main>
            <Heading align="center">Reset password</Heading>
            {hasParams ? <PasswordResetForm id={id} token={token} /> : <SendLinkForm />}
            <p>
              <Link to="/login" state={location.state}>
                <T>Back to login page</T>
              </Link>
            </p>
          </Main>
        </FormWrapper>
      </HeaderAndFormWrapper>
      <Footer />
    </PageWrapper>
  );
}
