import { useAtom } from 'jotai';

import { Card } from './item.styles';

import Content from './content';

import { placeProductQuery } from '../../../../../../../../store';

import type { Place, Product } from '@rewardopl/types';

type ItemProps = {
  placeId: Place['_id'];
  productId: Product['_id'];
};

export default function Item({ placeId, productId }: ItemProps) {
  const [product, setProduct] = useAtom(placeProductQuery([placeId, productId]));

  return (
    <Card>
      <Content product={product} setProduct={setProduct} />
    </Card>
  );
}
