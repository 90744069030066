import { useAtomValue } from 'jotai';

import { post } from '@rewardopl/utils/network';

import LikeButton from './like_button';

import { currentUserIdState } from '../../store';

import type { Place } from '@rewardopl/types';

type PlaceLikeButtonProps = {
  place: Place;
  setPlace: (place: Place) => void;
  showLabel?: boolean;
};

export default function PlaceLikeButton({ place, setPlace, showLabel }: PlaceLikeButtonProps) {
  const currentUserId = useAtomValue(currentUserIdState);

  const isLiked = place.liked_by?.includes(currentUserId);

  const action = `/api/places/${place._id}/${isLiked ? 'unlike' : 'like'}`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const response = await post(action);

    setPlace(response as Place);
  }

  return <LikeButton isLiked={isLiked} onClick={onClick} showLabel={showLabel} />;
}
