import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { T, useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import LinkButton from '@rewardopl/react-ui/link_button';

import { get, post } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import { HeaderAndFormWrapper, FormWrapper, Main, PageWrapper } from '../../login/index.styles';
import { ButtonWrapper } from '../../login/main/index.styles';

import LoadingText from '../../../loading_text';
import Heading from '../../../heading';
import Header from '../../../header';
import Footer from '../../../footer';

import { cardSubscriptionsState, maybeCurrentUserState } from '../../../../store';

import { APP_ID, VENDOR_CARD_ID } from '../../../../constants';

import type { CardSubscription, User } from '@rewardopl/types';

function ContinueInApp() {
  const location = useLocation();

  return (
    <PageWrapper>
      <HeaderAndFormWrapper>
        <Header />
        <FormWrapper>
          <Main>
            <Heading align="center">Continue in app</Heading>
            <ButtonWrapper>
              <LinkButton
                as="a"
                className="primary"
                // @ts-expect-error - see https://github.com/styled-components/styled-components/issues/4112
                href={`saladstory-app://${location.pathname}${location.search}`}
              >
                <T>Continue in app</T>
              </LinkButton>
            </ButtonWrapper>
          </Main>
        </FormWrapper>
      </HeaderAndFormWrapper>
      <Footer />
    </PageWrapper>
  );
}

type AuthCallbackRedirectProps = {
  code: string;
  returnPath: string;
  state: string | null;
};

let didSend = false;

function AuthCallbackRedirect({ code, returnPath, state }: AuthCallbackRedirectProps) {
  const { provider } = useParams();
  const navigate = useNavigate();
  const setCurrentUser = useSetAtom(maybeCurrentUserState);
  const setCardSubscriptions = useSetAtom(cardSubscriptionsState);
  const loginErrorString = useTranslation('Failed to log in');

  const action = `/api/auth/${provider}/callback${stringify({
    state: state ? encodeURIComponent(state) : '',
  })}`;

  useEffect(() => {
    if (didSend) {
      return;
    }

    didSend = true;

    (async () => {
      try {
        const user = (await post(action, {
          headers: {
            'app-id': APP_ID,
          },
          body: { code },
        })) as User;

        const cardSubscriptions = (await get(
          `/api/users/${user._id}/card_subscriptions`,
        )) as CardSubscription[];

        const isUserSubscribed = cardSubscriptions.some(
          (cardSubscription) => cardSubscription.card_id === VENDOR_CARD_ID,
        );

        let response: CardSubscription | undefined;
        if (!isUserSubscribed) {
          response = (await post(`/api/users/${user._id}/card_subscriptions`, {
            body: { card_id: VENDOR_CARD_ID },
          })) as CardSubscription;
        }

        setCurrentUser(user);
        setCardSubscriptions(response ? [...cardSubscriptions, response] : cardSubscriptions);

        navigate(returnPath);
      } catch {
        toast.error(loginErrorString);

        navigate('/login');
      } finally {
        didSend = false;
      }
    })();
  }, [action, code, loginErrorString, navigate, returnPath, setCardSubscriptions, setCurrentUser]);

  return <LoadingText />;
}

export default function AuthCallback() {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  if (!code) {
    return <Navigate to="/login" />;
  }

  const state = searchParams.get('state');

  const decodedState = (state ? JSON.parse(decodeURIComponent(state)) : null) as {
    returnPath?: string;
    mobile?: boolean;
  } | null;
  const returnPath = decodedState?.returnPath || '/';
  const isMobileLogin = decodedState?.mobile;

  if (isMobileLogin) {
    return <ContinueInApp />;
  }

  return <AuthCallbackRedirect code={code} returnPath={returnPath} state={state} />;
}
