import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const List = styled.div`
  width: 100vw;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-color: var(--gray-dark) transparent;
  scrollbar-width: thin;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  @media (screen-spanning: single-fold-vertical) {
    width: env(fold-left);
  }

  @media (horizontal-viewport-segments: 2) {
    width: env(viewport-segment-right 0 0);
  }
`;

export const ListItem = styled.div``;
