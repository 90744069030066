import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useAtomValue } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';
import { BarcodeIcon } from 'lucide-react';

import Dialog from '@rewardopl/react-ui/dialog';

import colors from '@rewardopl/styles/colors';

import { Button, Text, CodeWrapper } from './your_code_button.styles';

import Code from '../../common/code';

import { currentUserState } from '../../../store';

type YourCodeButtonProps = {
  onCreated?: () => void;
  onDestroyed?: () => void;
};

const YourCodeButton = forwardRef<{ closeDialog: () => void }, YourCodeButtonProps>(
  function YourCodeButton({ onCreated, onDestroyed }, ref) {
    const currentUser = useAtomValue(currentUserState);
    const [showDialog, setShowDialog] = useState(false);
    const yourCodeString = useTranslation('Your My Salad Story card');

    function openDialog(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      event.preventDefault();
      setShowDialog(true);

      onCreated?.();
    }

    const closeDialog = useCallback(() => {
      setShowDialog(false);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        closeDialog,
      }),
      [closeDialog],
    );

    return (
      <>
        <Button onClick={openDialog} title={yourCodeString} type="button">
          <BarcodeIcon />
        </Button>
        <Dialog
          isOpen={showDialog}
          onDestroyed={onDestroyed}
          onDismiss={closeDialog}
          title="Your My Salad Story card"
        >
          <Text>
            <T>Show the code every time you visit Salad Story to earn stamps for your purchases.</T>
          </Text>
          <CodeWrapper>
            <Code color={colors.text} id={currentUser._id} />
          </CodeWrapper>
        </Dialog>
      </>
    );
  },
);

export default YourCodeButton;
