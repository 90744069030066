import { useTranslation } from '@wojtekmaj/react-t';

import { getUserName } from '@rewardopl/utils/user';

import { Avatar as AvatarElement } from './avatar.styles';

import avatarPlaceholder from '../../static/avatar-placeholder.svg';

import type { HeavilyFilteredUser } from '@rewardopl/types';

type AvatarProps = {
  backgroundColor?: string;
  size?: number;
  user: HeavilyFilteredUser;
};

export default function Avatar({
  backgroundColor = 'var(--background)',
  size = 50,
  user,
}: AvatarProps) {
  const userName = getUserName(user);
  const userNameString = useTranslation(userName);

  function onError(
    event: React.SyntheticEvent<HTMLImageElement, Event> & { target: HTMLImageElement },
  ) {
    const { target } = event;
    target.src = avatarPlaceholder;
  }

  return (
    <AvatarElement
      alt={userNameString}
      backgroundColor={backgroundColor}
      width={size}
      height={size}
      onError={onError}
      src={
        user.avatar ? `/api/uploads/${user.avatar}?w=${size * devicePixelRatio}` : avatarPlaceholder
      }
    />
  );
}
