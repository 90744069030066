import styled, { css } from 'styled-components';

import { aboveContent } from '@rewardopl/styles/z-index';

import { supportsStyleChangeOnHover } from '../utils/feature_detection';

export default styled.div<{
  to?: string;
  onClick?: () => void;
}>`
  width: 100%;
  display: block;
  position: relative;
  padding: var(--gap);
  background: var(--card-background);
  border: 0;
  border-radius: var(--border-radius);
  color: inherit;
  font: inherit;
  text-align: left;
  transition:
    box-shadow ease-in-out 200ms,
    transform ease-in-out 200ms;
  outline: none;

  ${(props) =>
    (props.to || props.onClick) &&
    css`
      cursor: pointer;

      ${
        supportsStyleChangeOnHover &&
        css`
        &:hover,
        &:focus {
          text-decoration: none;
          transform: translateY(-3px);
          z-index: ${aboveContent};
        }
      `
      }
    `}
`;
