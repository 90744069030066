import saladStoryIcon from '@rewardopl/assets/logos/saladstory.svg';

import { Wrapper } from './header.styles';

export default function Header() {
  return (
    <Wrapper>
      <img width={233} height={79} src={saladStoryIcon} alt="Salad Story" />
    </Wrapper>
  );
}
