import { cloneElement, Component, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import * as Sentry from '@sentry/react';

import { JotaiContext } from '../jotai_provider';

import { maybeCurrentUserLoadable } from '../store';

import { PROD } from '../env';

import type { Location } from 'react-router-dom';
import type { User } from '@rewardopl/types';

const isProduction = PROD;

type ErrorBoundaryProps = {
  children: React.ReactNode;
  currentUser: User | null;
  fallback?: React.ReactElement<{ resetError?: () => void }> | null;
  location: Location;
  resetStore: () => void;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.resetError = this.resetError.bind(this);
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);

    if (error instanceof Error && error.message === 'Forbidden') {
      const { currentUser } = this.props;

      if (currentUser) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      return;
    }

    localStorage.clear();

    if (isProduction) {
      Sentry.captureException(error);
    }

    this.setState({ hasError: true });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { location: prevLocation } = prevProps;

    if (prevLocation.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  resetError() {
    const { resetStore } = this.props;

    resetStore();

    this.setState({ hasError: false });
  }

  render() {
    const { resetError } = this;
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback ? cloneElement(fallback, { resetError }) : null;
    }

    return children;
  }
}

function withCurrentUser<P extends { currentUser: User | null }>(
  InternalComponent: React.ComponentType<P>,
) {
  function WithCurrentUserInternal(props: Omit<P, 'currentUser'>) {
    const currentUserLoadable = useAtomValue(maybeCurrentUserLoadable);

    return (
      <InternalComponent
        {...(props as P)}
        currentUser={(currentUserLoadable.state === 'hasData' && currentUserLoadable.data) || null}
      />
    );
  }

  WithCurrentUserInternal.displayName = `withCurrentUser(${InternalComponent.name})`;

  return WithCurrentUserInternal;
}

function withLocation<P extends { location: Location }>(InternalComponent: React.ComponentType<P>) {
  function WithLocationInternal(props: Omit<P, 'location'>) {
    const location = useLocation();

    return <InternalComponent {...(props as P)} location={location} />;
  }

  WithLocationInternal.displayName = `withLocation(${InternalComponent.name})`;

  return WithLocationInternal;
}

function withJotai<P extends { resetStore: () => void }>(
  InternalComponent: React.ComponentType<P>,
) {
  function WithJotaiInternal(props: Omit<P, 'resetStore'>) {
    const jotaiContext = useContext(JotaiContext);

    if (!jotaiContext) {
      throw new Error('Jotai context not found');
    }

    const { resetStore } = jotaiContext;

    return <InternalComponent {...(props as P)} resetStore={resetStore} />;
  }

  WithJotaiInternal.displayName = `withJotai(${InternalComponent.name})`;

  return WithJotaiInternal;
}

export default withJotai(withLocation(withCurrentUser(ErrorBoundary)));
