import styled, { css } from 'styled-components';

import { navigation } from '@rewardopl/styles/z-index';

import { Wrapper as SaladStoryLogo } from '../../common/saladstory_logo.styles';

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  grid-area: aside;
  margin-bottom: calc(env(safe-area-inset-bottom) * -1);
  ${() =>
    navigator.standalone &&
    css`
      margin-bottom: -15px;
      margin-bottom: calc(max(15px, env(safe-area-inset-bottom)) * -1);
    `}
  padding: 0;
  padding-bottom: env(safe-area-inset-bottom);
  ${() =>
    navigator.standalone &&
    css`
      padding-bottom: 15px;
      padding-bottom: max(15px, env(safe-area-inset-bottom));
    `}
  padding-left: env(safe-area-inset-left);
  background: var(--menu-background);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  position: relative;
  z-index: ${navigation};

  ${SaladStoryLogo} {
    display: none;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    padding: 15px;
    padding-left: max(15px, env(safe-area-inset-left));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: auto;

    ${SaladStoryLogo} {
      display: block;
      margin: 5px auto calc(var(--gap) + 5px);
    }
  }
`;
