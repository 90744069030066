import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--gap);
`;

export const LogoWrapper = styled.div`
  line-height: 0;
`;

export const HeaderContentWrapper = styled.div``;

export const MessageDate = styled.p`
  margin: 0;
  color: var(--text-light);
  font-size: 12px;
`;

export const Subject = styled.h3`
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 16px;
`;

export const Sender = styled.p`
  margin-top: 7px;
  margin-bottom: 0;
  color: var(--text-light);
  font-size: 12px;
`;

export const Body = styled.p`
  margin: 0;
  color: var(--text-light);
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const Picture = styled.img`
  max-width: 100%;
  height: auto;
`;
