// Not clickable elements that should be placed below content
export const belowContent = -1;
// Elements that should be placed above content or that touch other elements and have hover styling
export const aboveContent = 1;
export const header = 2;
export const main = 2;
export const navigation = 3;
export const yourCodeButton = 4;
export const dialog = 5;
export const popover = 6;
