import saladStoryLogo from '@rewardopl/assets/logos/saladstory.svg';

import { Wrapper, Image } from './saladstory_logo.styles';

type SaladStoryLogoProps = { width?: number };

export default function SaladStoryLogo({ width = 180 }: SaladStoryLogoProps) {
  const height = width * (90 / 366);

  return (
    <Wrapper>
      <Image width={width} height={height} src={saladStoryLogo} alt="Salad Story" />
    </Wrapper>
  );
}
