import { useEffect, useId } from 'react';
import { toCanvas } from '@bwip-js/browser';

type BarcodeProps = {
  bgColor?: string;
  fgColor?: string;
  value: string;
};

export default function Barcode({ bgColor = '#fff', fgColor = '#000', value }: BarcodeProps) {
  const id = useId();

  const valueNumbersOnly = value.replace(/\D/g, '');

  useEffect(() => {
    toCanvas(id, {
      bcid: 'code128',
      text: valueNumbersOnly,
      scale: 3,
      height: 10,
      includetext: false,
      backgroundcolor: bgColor,
      textcolor: fgColor,
    });
  }, [bgColor, fgColor, id, valueNumbersOnly]);

  return <canvas id={id} />;
}
