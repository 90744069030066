import { Helmet } from 'react-helmet-async';
import { useTranslation } from '@wojtekmaj/react-t';

import { PROD, MODE } from '../env';

const shortMode = MODE === 'development' ? 'dev' : MODE;

const title = PROD ? 'Salad Story' : `Salad Story ${shortMode.toUpperCase()}`;

type TitleProps = {
  children?: string;
};

export default function Title({ children }: TitleProps) {
  const translatedChildren = useTranslation(children);

  return (
    <Helmet>
      <title>{translatedChildren ? `${translatedChildren} • ${title}` : title}</title>
    </Helmet>
  );
}
