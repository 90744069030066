import { createContext, useCallback, useState } from 'react';
import { Provider } from 'jotai';

export const JotaiContext = createContext<{
  resetStore: () => void;
} | null>(null);

export default function JotaiProvider({ children }: { children: React.ReactNode }) {
  const [key, setKey] = useState(1);

  const resetStore = useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <Provider key={key}>
      <JotaiContext.Provider value={{ resetStore }}>{children}</JotaiContext.Provider>
    </Provider>
  );
}
