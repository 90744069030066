import { Navigate, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import { Image, Description, ContentWrapper } from './index.styles';

import PageWrapper from '../../../../../../page_wrapper';
import HeaderWithButton from '../../../../header_with_button';

import { addLinks } from '../../../../../../../utils/helpers';

import { vendorCardOfferQuery } from '../../../../../../../store';

export default function OfferPage() {
  const { offer_id: offerId } = useParams();

  if (!offerId) {
    throw new Error('offerId is undefined');
  }

  const offer = useAtomValue(vendorCardOfferQuery(offerId));

  if (!offer) {
    return <Navigate to=".." />;
  }

  const { description, name, picture } = offer;

  return (
    <>
      <HeaderWithButton>{name}</HeaderWithButton>
      <PageWrapper>
        {picture ? <Image src={`/api/uploads/${picture}`} alt={name} /> : null}
        <ContentWrapper>
          {description ? <Description>{addLinks(description)}</Description> : null}
        </ContentWrapper>
      </PageWrapper>
    </>
  );
}
