import { useAtomValue, useSetAtom } from 'jotai';

import { post } from '@rewardopl/utils/network';

import LikeButton from './like_button';

import { currentUserIdState, vendorProductQuery } from '../../store';

import type { Product } from '@rewardopl/types';

type ProductLikeButtonProps = {
  product: Product;
  setProduct: (product: Product) => void;
};

export default function ProductLikeButton({ product, setProduct }: ProductLikeButtonProps) {
  const { _id: productId } = product;

  const setVendorProduct = useSetAtom(vendorProductQuery(productId));
  const currentUserId = useAtomValue(currentUserIdState);

  const isLiked = product.liked_by?.includes(currentUserId);

  const action = `/api/products/${product._id}/${isLiked ? 'unlike' : 'like'}`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const response = await post(action);

    setProduct(response as Product);
    setVendorProduct(response as Product);
  }

  return <LikeButton isLiked={isLiked} onClick={onClick} />;
}
