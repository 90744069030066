import T from '@wojtekmaj/react-t';

import Badge from '../badge.styles';

type ProductLabelProps = {
  children?: string;
  variant?:
    | 'contains_alcohol'
    | 'alcohol_free'
    | 'gluten_free'
    | 'lactose_free'
    | 'sugar_free'
    | 'vegan'
    | 'vegetarian'
    | (string & Record<never, never>);
};

export default function ProductLabel({ children, variant }: ProductLabelProps) {
  const label = (() => {
    switch (variant) {
      case 'contains_alcohol':
        return 'with alcohol';
      case 'alcohol_free':
        return 'alcohol free';
      case 'gluten_free':
        return 'gluten free';
      case 'lactose_free':
        return 'lactose free';
      case 'sugar_free':
        return 'sugar free';
      case 'vegan':
        return 'vegan';
      case 'vegetarian':
        return 'vegetarian';
      default:
        return children;
    }
  })();

  return (
    <Badge>
      <T>{label}</T>
    </Badge>
  );
}
