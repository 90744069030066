import T, { useTranslation } from '@wojtekmaj/react-t';

import LinkButton from '@rewardopl/react-ui/link_button';

import { PageWrapper, ContentWrapper, Icon, Title, Description } from './transaction.styles';

import trophyIcon from '../../../../../../static/trophy.svg';

export default function Transaction() {
  const sectionName = useTranslation('Rewards');

  return (
    <PageWrapper>
      <ContentWrapper>
        <Icon>
          <img width={124} height={124} src={trophyIcon} alt="" />
        </Icon>
        <Title>
          <T>Reward is yours!</T>
        </Title>
        <Description>
          <T sectionName={sectionName}>
            {
              'Stamps were taken from your account. See details of your reward on card page in “{sectionName}” section.'
            }
          </T>
        </Description>
        <LinkButton to="../..">
          <T>Back to Home screen</T>
        </LinkButton>
      </ContentWrapper>
    </PageWrapper>
  );
}
