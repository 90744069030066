import styled from 'styled-components';

import Section from '../../../../../../../section.styles';

export const Wrapper = styled(Section)``;

export const Heading = styled.h2``;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--gap);
  gap: 12px;
`;

export const Text = styled.p`
  margin: 0;
`;
