import { Logo as LogoElement } from './logo.styles';

import logoPlaceholder from '../../static/logo-placeholder.svg';

import type { Business } from '@rewardopl/types';

type LogoProps = {
  borderWidth?: number;
  business: Business;
  size?: number;
};

export default function Logo({ borderWidth = 4, business, size = 50 }: LogoProps) {
  function onError(
    event: React.SyntheticEvent<HTMLImageElement, Event> & { target: HTMLImageElement },
  ) {
    const { target } = event;
    target.src = logoPlaceholder;
  }

  return (
    <LogoElement
      alt={business.name}
      borderWidth={borderWidth}
      width={size}
      height={size}
      onError={onError}
      src={
        business.logo
          ? `/api/uploads/${business.logo}?w=${size * devicePixelRatio}`
          : logoPlaceholder
      }
    />
  );
}
