import { HeartIcon } from 'lucide-react';
import T, { useTranslation } from '@wojtekmaj/react-t';

import { Button, Text } from './like_button.styles';

type LikeButtonProps = {
  isLiked?: boolean | undefined;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showLabel?: boolean;
};

export default function LikeButton({ isLiked, onClick, showLabel = true }: LikeButtonProps) {
  const likeString = useTranslation('Like');

  return (
    <Button
      aria-label={showLabel ? undefined : likeString}
      onClick={onClick}
      showLabel={showLabel}
      type="button"
    >
      <HeartIcon
        fill={isLiked ? 'currentColor' : 'transparent'}
        strokeWidth={2 * (24 / 20)}
        size={20}
      />
      {showLabel ? (
        <Text>
          <T>Like</T>
        </Text>
      ) : null}
    </Button>
  );
}
