import { useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import Button from '@rewardopl/react-ui/button';

import { PageWrapper, ContentWrapper, Image, Title, Sub } from './index.styles';

import SaladStoryLogo from '../../common/saladstory_logo';

import errorIcon from '../../../static/error.svg';

type ErrorPageProps = {
  resetError?: () => void;
};

export default function ErrorPage({ resetError }: ErrorPageProps) {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        <SaladStoryLogo width={164} />
        <Image width={124} height={124} src={errorIcon} alt="" />
        <Title>
          <T>Oops, something went wrong</T>
        </Title>
        <Sub>
          <T>
            Please be patient. Our team has been notified and is working on resolving this issue.
          </T>
        </Sub>
        <Button className="primary" onClick={resetError}>
          <T>Try again</T>
        </Button>
        <Button onClick={goBack}>
          <T>Go back</T>
        </Button>
      </ContentWrapper>
    </PageWrapper>
  );
}
