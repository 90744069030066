import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import CheckboxField from '@rewardopl/react-ui/checkbox_field';

import { patch } from '@rewardopl/utils/network';

import {
  Wrapper,
  ScrollContainer,
  ContentWrapper,
  Heading,
  Footer,
  ButtonWrapper,
} from '../accept_terms_and_conditions/index.styles';

import { Indent, Line, Small } from '../../../../login/email/form.styles';

import TermsAndConditionsLink from '../../../../../terms_and_conditions_link';
import PrivacyPolicyLink from '../../../../../privacy_policy_link';

import { currentUserState } from '../../../../../../store';

import type { User } from '@rewardopl/types';

export default function AppConsentsPage() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useAtom(currentUserState);
  const acceptTermsErrorString = useTranslation('Failed to accept Terms and Conditions');

  const [emailMarketingAccepted, setEmailMarketingAccepted] = useState(false);
  const [smsMarketingAccepted, setSmsMarketingAccepted] = useState(false);
  const marketingAccepted = emailMarketingAccepted || smsMarketingAccepted;

  const { terms_accepted: termsAccepted } = currentUser;

  if (termsAccepted) {
    return <Navigate to=".." />;
  }

  const { _id: userId } = currentUser;

  const action = `/api/users/${userId}`;

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = event.currentTarget;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const user = (await patch(action, {
        body: formData,
      })) as User;

      setCurrentUser(user);

      navigate('../');
    } catch {
      toast.error(acceptTermsErrorString);
    }
  }

  return (
    <form action={action} method="PATCH" onSubmit={onSubmit}>
      <Wrapper>
        <ScrollContainer>
          <ContentWrapper>
            <Heading>
              <T>Terms and Conditions</T>
            </Heading>
            <CheckboxField
              name="terms_accepted"
              label={
                <>
                  <T>I have read and agree to the</T> <TermsAndConditionsLink /> <T>and</T>{' '}
                  <PrivacyPolicyLink />.
                </>
              }
              required
            />
            <CheckboxField
              checked={marketingAccepted}
              name="saladstory_marketing_accepted"
              label={
                <T>
                  I consent to Salad Story S.A. providing information about the latest promotions,
                  products and services of its own and about the promotions, products and services
                  of its partners via e-mail or push messages.
                </T>
              }
              onChange={() => {
                setEmailMarketingAccepted(!marketingAccepted);
                setSmsMarketingAccepted(!marketingAccepted);
              }}
            />
            <Indent>
              <CheckboxField
                checked={smsMarketingAccepted}
                label={<T>SMS</T>}
                name="saladstory_marketing_sms_accepted"
                onChange={() => {
                  setSmsMarketingAccepted(!smsMarketingAccepted);
                }}
              />
              <CheckboxField
                checked={emailMarketingAccepted}
                name="saladstory_marketing_email_accepted"
                label={<T>E-mail</T>}
                onChange={() => {
                  setEmailMarketingAccepted(!emailMarketingAccepted);
                }}
              />
            </Indent>
            <Line />
            <Small>
              <T link={<a href="mailto:kontakt@saladstory.com">kontakt@saladstory.com</a>}>
                {
                  'You can withdraw your consent at any time. Withdrawal of consent does not affect the legality of the marketing information sent so far. To do this, write to us at {link} or log in and change the settings in your account.'
                }
              </T>
            </Small>
            <Small>
              <T link={<PrivacyPolicyLink />}>
                {
                  'The administrator of your personal data is Salad Story S.A. with its registered office in Warsaw (02-222), Al. Jerozolimskie 179. Your personal data will be processed for the purpose of registering and managing your user account in the My Salad Story loyalty program, including sending you marketing messages related to your participation in the loyalty program (also using profiling), as well as for the statistical and analytical purposes of the administrator. In the event of expressing appropriate consent, your data will also be processed for the purpose of sending you marketing content in the form of e-mail, to the e-mail address provided. More information on the processing of personal data, including your rights, can be found in our {link}.'
                }
              </T>
            </Small>
          </ContentWrapper>
        </ScrollContainer>
        <Footer>
          <ButtonWrapper>
            <Button className="primary" type="submit">
              <T>Continue</T>
            </Button>
          </ButtonWrapper>
        </Footer>
      </Wrapper>
    </form>
  );
}
