import styled from 'styled-components';

import Frame from '../../../../../../../frame.styles';

export const Card = styled(Frame)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
`;
