import {
  formatTime as commonFormatTime,
  formatLongDate as commonFormatLongDate,
  formatShortDate as commonFormatShortDate,
  formatLongMonthDay as commonFormatLongMonthDay,
  formatShortMonthDay as commonFormatShortMonthDay,
  formatRelative as commonFormatRelative,
} from '@rewardopl/utils/date';

import { locale } from '../i18n/i18n';

type Options = {
  locale?: 'de' | 'en' | 'pl' | 'uk';
};

function getOptionsWithLocale(options: Options): Options {
  const optionsWithLocale = {
    ...options,
  };

  if (locale) {
    optionsWithLocale.locale = locale;
  }

  return optionsWithLocale;
}

export function withLocale<T, U>(
  fn: (value?: T, options?: Options) => U,
): (value?: T, options?: Options) => U {
  return function withLocaleInternal(value?: T, options = {}) {
    const optionsWithLocale = getOptionsWithLocale(options);

    return fn(value, optionsWithLocale);
  };
}

export const formatTime = withLocale(commonFormatTime);
export const formatLongDate = withLocale(commonFormatLongDate);
export const formatShortDate = withLocale(commonFormatShortDate);
export const formatLongMonthDay = withLocale(commonFormatLongMonthDay);
export const formatShortMonthDay = withLocale(commonFormatShortMonthDay);
export const formatRelative = withLocale(commonFormatRelative);
