import styled from 'styled-components';

import {
  Wrapper,
  ScrollContainer,
  ContentWrapper as AcceptTermsAndConditionsContentWrapper,
  Footer,
  ButtonWrapper as AcceptTermsAndConditionsButtonWrapper,
} from '../../accept_terms_and_conditions/index.styles';

export { Wrapper, ScrollContainer, Footer };

export const ContentWrapper = styled(AcceptTermsAndConditionsContentWrapper)`
  text-align: center;
`;

export const Icon = styled.div`
  margin: 2em auto 0;
`;

export const CheckIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #63d375;
  border-radius: 50%;

  svg {
    stroke: var(--white);
  }
`;

export const Name = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const Price = styled.div`
  margin-bottom: 3.5em;
  font-size: 12px;
  line-height: 1.2;
`;

export const Amount = styled.span`
  display: block;
  font-size: 72px;
`;

export const Description = styled.p`
  margin: 3em auto 2em;
  white-space: pre-wrap;
`;

export const Hint = styled.p`
  font-size: 12px;
`;

export const ButtonWrapper = styled(AcceptTermsAndConditionsButtonWrapper)`
  a,
  button {
    flex-grow: 0;
  }
`;
