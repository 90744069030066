import styled from 'styled-components';
import { Link as CommonLink } from 'react-router-dom';

import { header } from '@rewardopl/styles/z-index';

import { Wrapper as SaladStoryLogo } from '../../common/saladstory_logo.styles';
import { Avatar } from '../../common/avatar.styles';

export const headerHeight = 60;

export const Wrapper = styled.header`
  width: 100%;
  max-width: 960px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 16px 12px;
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-right: max(12px, env(safe-area-inset-right));
  background: var(--header-background);
  z-index: ${header};
  gap: var(--gap);

  @media screen and (min-width: 769px) {
    padding-left: var(--gap);
    padding-right: max(var(--gap), env(safe-area-inset-right));
  }

  ${SaladStoryLogo} {
    margin: auto;
  }
`;

export const Link = styled(CommonLink)`
  border-radius: var(--border-radius-round);
  line-height: 0;

  ${Avatar} {
    border-width: 0px;
  }
`;
