import { UAParser } from 'ua-parser-js';

const { getBrowser, getEngine, getOS } = new UAParser();

const { name: osName, version: osVersion } = getOS();
const { name: engineName, version: engineVersion } = getEngine();
const { name: browserName, version: browserVersion } = getBrowser();

export const supportsBarcodeDetector =
  typeof window !== 'undefined' &&
  'BarcodeDetector' in window &&
  /**
   * Chromium browsers prior to version 113 on macOS Ventura (13.0) and up failed to access Apple
   * Neural Engine for BarcodeDetector, causing BarcodeDetector to silently fail.
   * Read more: https://issues.chromium.org/issues/40245611
   */
  !(
    osName === 'macOS' &&
    osVersion &&
    Number.parseInt(osVersion) >= 13 &&
    engineName === 'Blink' &&
    engineVersion &&
    Number.parseInt(engineVersion) >= 113
  );

export const supportsStyleChangeOnHover =
  browserName !== 'Mobile Safari' || (browserVersion && Number.parseInt(browserVersion) >= 13);

export const supportsThemeColor = browserName !== 'Mobile Safari';
