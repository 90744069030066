import styled from 'styled-components';

import { header } from '@rewardopl/styles/z-index';

import CommmonBackButton from './back_button';

export const Wrapper = styled.div`
  position: relative;
  padding: 20px calc(var(--gap) + 26px);
  padding-top: calc(20px + env(safe-area-inset-top));
  z-index: ${header};
  text-align: center;

  @media screen and (min-width: 769px) {
    justify-content: flex-end;
  }
`;

export const BackButton = styled(CommmonBackButton)`
  position: absolute;
  top: 20px;
  top: calc(20px + env(safe-area-inset-top));
  left: var(--gap);
`;

export const Title = styled.h1`
  font-size: 20px;
  margin: -3.333px 0;
`;

export const ExtraButton = styled.div`
  position: absolute;
  top: 20px;
  top: calc(20px + env(safe-area-inset-top));
  right: var(--gap);
`;
