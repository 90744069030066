import styled from 'styled-components';

import {
  Button as CommonButton,
  buttonFocusStyles,
  buttonHoverStyles,
} from '@rewardopl/react-ui/button/index.styles';

const fbColors = {
  primary: '#1877f2',
  primaryDark: '#0c64b6',
};

export const Button = styled(CommonButton)`
  --fb-primary: ${fbColors.primary};
  --fb-dark: ${fbColors.primaryDark};
  display: inline-block;
  padding: 10px 14px;
  border: 0;
  background-color: var(--fb-primary);
  color: white;

  &:hover {
    ${buttonHoverStyles}
    background-color: var(--fb-dark);
  }

  &:focus {
    ${buttonFocusStyles}
    box-shadow: none;
    color: white;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: -26%;
`;
