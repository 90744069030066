import { Suspense, lazy, useRef } from 'react';
import { useAtomValue } from 'jotai';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Wrapper, HeaderPlaceholder, Main } from './content.styles';

import LoadingText from '../../loading_text';
import ErrorBoundary from '../../error_boundary';

import Title from '../../title';
import ScrollToTop from '../../scroll_to_top';
import Header from './header';

import OffersPage from './home/card_subscription/offers';
import PlacesPage from './home/card_subscription/places';
import RewardsPage from './home/card_subscription/rewards';
import RewardReceivedPage from './home/card_subscription/reward_received';
import AppConsentsPage from './home/card_subscription/app_consents';
import AcceptTermsAndConditionsPage from './home/card_subscription/accept_terms_and_conditions';
import TermsAndConditionsPage from './home/card_subscription/terms_and_conditions';

import { vendorCardState } from '../../../store';

const HomePage = lazy(() => import('./home'));
const MapPage = lazy(() => import('./map'));
const FavoritesPage = lazy(() => import('./favorites'));
const InboxPage = lazy(() => import('./inbox'));
const ChallengesPage = lazy(() => import('./challenges'));
const ProductsPage = lazy(() => import('./products'));
const ProfilePage = lazy(() => import('./profile'));
const CameraPage = lazy(() => import('./camera'));
const RedeemPage = lazy(() => import('./redeem'));

const PWAPopover = lazy(() => import('./pwa_popover'));

export default function Content() {
  const wrapper = useRef<HTMLDivElement>(null);

  const card = useAtomValue(vendorCardState);

  const hasTermsAndConditions = Boolean(card.terms_and_conditions);

  return (
    <>
      <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <PWAPopover />
        </Suspense>
      </ErrorBoundary>
      <Wrapper ref={wrapper}>
        <Title />
        <ScrollToTop wrapper={wrapper} />
        <Routes>
          <Route path="" element={<Header />} />
          <Route path="favorites/*" element={<HeaderPlaceholder />} />
          <Route path="challenges" element={<HeaderPlaceholder />} />
          <Route path="inbox" element={<HeaderPlaceholder />} />
          <Route path="products" element={<HeaderPlaceholder />} />
          <Route path="products/available" element={<HeaderPlaceholder />} />
          <Route path="products/favorited" element={<HeaderPlaceholder />} />
        </Routes>
        <Main>
          <Suspense fallback={<LoadingText />}>
            <Routes>
              <Route path="" element={<HomePage />} />
              <Route path="map/*" element={<MapPage />} />
              <Route path="favorites/*" element={<FavoritesPage />} />
              <Route path="inbox/*" element={<InboxPage />} />
              <Route path="challenges/*" element={<ChallengesPage />} />
              <Route path="profile/*" element={<ProfilePage />} />
              <Route path="camera/*" element={<CameraPage />} />
              <Route path="redeem/*" element={<RedeemPage />} />
              <Route path="offers/*" element={<OffersPage />} />
              <Route path="places/*" element={<PlacesPage />} />
              <Route path="products/*" element={<ProductsPage />} />
              <Route path="rewards/*" element={<RewardsPage />} />
              <Route path="reward_received/*" element={<RewardReceivedPage />} />
              <Route path="app_consents/*" element={<AppConsentsPage />} />
              {hasTermsAndConditions ? (
                <Route
                  path="accept_terms_and_conditions/*"
                  element={<AcceptTermsAndConditionsPage />}
                />
              ) : null}
              {hasTermsAndConditions ? (
                <Route path="terms_and_conditions/*" element={<TermsAndConditionsPage />} />
              ) : null}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </Main>
      </Wrapper>
    </>
  );
}
