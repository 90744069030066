import { useAtomValue } from 'jotai';

import { ContentWrapper, Description } from './index.styles';

import HeaderWithButton from '../../../header_with_button';

import { vendorCardState } from '../../../../../../store';

export default function TermsAndConditionsPage() {
  const card = useAtomValue(vendorCardState);

  return (
    <>
      <HeaderWithButton>Terms and Conditions</HeaderWithButton>
      <ContentWrapper>
        <Description>{card.terms_and_conditions}</Description>
      </ContentWrapper>
    </>
  );
}
