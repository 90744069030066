import { Route, Routes } from 'react-router-dom';

import { PageWrapper, HeaderAndFormWrapper, FormWrapper, Main } from './index.styles';

import Heading from '../../heading';
import Header from '../../header';
import Footer from '../../footer';

import LoginMain from './main';
import LoginEmail from './email';
import LoginTwoStepEmail from './two_step_email';

export default function LoginPage() {
  return (
    <PageWrapper>
      <HeaderAndFormWrapper>
        <Header />
        <FormWrapper>
          <Main>
            <Heading align="center">Login</Heading>
            <Routes>
              <Route path="" element={<LoginMain />} />
              <Route path="email" element={<LoginEmail />} />
              <Route path="two_step_email/*" element={<LoginTwoStepEmail />} />
            </Routes>
          </Main>
        </FormWrapper>
      </HeaderAndFormWrapper>
      <Footer />
    </PageWrapper>
  );
}
