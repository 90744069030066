import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import LoginTwoStepEmailStepEmail from './email';
import LoginTwoStepEmailStepPassword from './password';

export default function LoginTwoStepEmail() {
  const location = useLocation();

  const { state } = location;

  return (
    <Routes>
      <Route path="email" element={<LoginTwoStepEmailStepEmail />} />
      <Route path="password" element={<LoginTwoStepEmailStepPassword />} />
      <Route path="*" element={<Navigate to="email" state={state} />} />
    </Routes>
  );
}
