import styled from 'styled-components';

export const headerHeight = 60;

export const Wrapper = styled.header`
  min-height: ${headerHeight}px;
  padding: var(--gap) var(--gap) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap);
`;
