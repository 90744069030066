import { Link, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import LoginTwoStepEmailStepEmailForm from './form';

export default function LoginTwoStepEmailStepEmail() {
  const location = useLocation();

  return (
    <>
      <LoginTwoStepEmailStepEmailForm />
      <p>
        <T>Don’t have an account yet?</T>{' '}
        <Link to="/register_with_details" state={location.state}>
          <T>Register</T>
        </Link>
        .
      </p>
    </>
  );
}
