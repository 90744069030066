import { Fragment } from 'react';
import { useAtom } from 'jotai';
import T from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import Button from '@rewardopl/react-ui/button';
import TextareaField from '@rewardopl/react-ui/textarea_field';

import { Wrapper, Form, ButtonWrapper } from './message_poll.styles';

import Fieldset from './fieldset';
import CheckboxGroup from './checkbox_group';
import RadioGroup from './radio_group';

import { post } from '@rewardopl/utils/network';

import { vendorMessagePollQuery } from '../../../../../store';

import type { Message, PollResponse } from '@rewardopl/types';

type MessagePollProps = {
  buttonClassName?: string;
  messageId: Message['_id'];
};

export default function MessagePoll({ buttonClassName, messageId }: MessagePollProps) {
  const [messagePoll, setMessagePoll] = useAtom(vendorMessagePollQuery(messageId));

  if (!messagePoll) {
    return null;
  }

  const action = `/api/messages/${messageId}/poll/responses`;

  async function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (!messagePoll) {
      return;
    }

    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    (await post(action, {
      body: new FormData(form),
    })) as PollResponse;

    setMessagePoll({ ...messagePoll, can_respond: false });
  }

  const { can_respond: canRespond, questions } = messagePoll;

  if (!canRespond) {
    return (
      <Wrapper>
        <T>Thank you for your response!</T>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Form action={action} method="POST">
        {questions.map((question, questionIndex) => {
          const content = (() => {
            const { type: questionType } = question;

            switch (questionType) {
              case 'single': {
                const { answers } = question;

                return (
                  <Fieldset label={question.question}>
                    <RadioGroup
                      name={`answers[${questionIndex}][answer_ids]`}
                      options={answers.map((answer) => ({
                        label: answer.text,
                        value: answer._id,
                      }))}
                    />
                  </Fieldset>
                );
              }
              case 'multiple': {
                const { answers } = question;

                return (
                  <Fieldset label={question.question}>
                    <CheckboxGroup
                      name={`answers[${questionIndex}][answer_ids]`}
                      options={answers.map((answer) => ({
                        label: answer.text,
                        value: answer._id,
                      }))}
                    />
                  </Fieldset>
                );
              }
              case 'free_text':
                return (
                  <TextareaField
                    label={question.question}
                    name={`answers[${questionIndex}][text]`}
                    required
                  />
                );
              default:
                throw new Error(`Unsupported question type: ${questionType satisfies never}`);
            }
          })();

          return (
            <Fragment key={question._id}>
              <input
                type="hidden"
                name={`answers[${questionIndex}][question_id]`}
                value={question._id}
              />
              {content}
            </Fragment>
          );
        })}
        <ButtonWrapper>
          <AsyncButton as={Button} className={buttonClassName} onClick={onClick} type="submit">
            <T>Submit</T>
          </AsyncButton>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
}
