import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--gap);
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
