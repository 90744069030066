import { useState } from 'react';
import { getDaysInMonth } from 'date-fns';

import { makeNumberArray } from '@rewardopl/utils/array';

import SelectField from '@rewardopl/react-ui/select_field';

import Fieldset from '../../index/inbox/message/fieldset';

import { MonthFieldWrapper, OptionsWrapper } from './birthday_field.styles';

import { locale } from '../../../../i18n/i18n';

type BirthdayFieldProps = {
  disabled?: boolean;
  hint?: string;
  label?: string;
  name: string;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string;
};

type DateObject = {
  year?: string;
  month: string | undefined;
  day: string | undefined;
};

function getFormattedMonthName(month: number) {
  const date = new Date();
  date.setMonth(Number(month) - 1);

  return new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
}

const months = makeNumberArray(12).map((month) => {
  return {
    label: getFormattedMonthName(month),
    value: `${month}`.padStart(2, '0'),
  };
});

const fullDatePattern = /^\d{4}-\d{2}-\d{2}$/;
const partialDatePattern = /^\d{2}-\d{2}$/;

/**
 * Decodes a full date string into an object with year (optional), month and day properties.
 *
 * @param {string} date A full date string in the format 'YYYY-MM-DD' or 'MM-DD'
 * @returns {DateObject} An object with year (optional), month and day properties
 */
function decodeYear(date: string): DateObject {
  if (fullDatePattern.test(date)) {
    const [year, month, day] = date.split('-');

    if (year === undefined || month === undefined || day === undefined) {
      throw new Error(`Invalid date: ${date}`);
    }

    return { year, month, day };
  }

  if (partialDatePattern.test(date)) {
    const [month, day] = date.split('-');

    if (month === undefined || day === undefined) {
      throw new Error(`Invalid date: ${date}`);
    }

    return { month, day };
  }

  return { month: undefined, day: undefined };
}

/**
 * Encodes a date object into a full date string.
 *
 * @param {DateObject} dateObject The date object to encode
 * @returns {string} A full date string in the format 'YYYY-MM-DD'
 */
function encodeDate(dateObject: DateObject): string {
  const { year, month, day } = dateObject;

  if (year !== undefined) {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }

  if (month === undefined || day === undefined) {
    return '';
  }

  return `${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
}

export default function BirthdayField({
  disabled,
  hint,
  label,
  name,
  onChange,
  required = true,
  value,
}: BirthdayFieldProps) {
  const decodedDate = value ? decodeYear(value) : { month: undefined, day: undefined };
  const [year, setYear] = useState(
    decodedDate && 'year' in decodedDate ? decodedDate.year : undefined,
  );
  const [month, setMonth] = useState(decodedDate?.month);
  const [day, setDay] = useState(decodedDate?.day);
  const [encodedOpeningHours, setEncodedOpeningHours] = useState(encodeDate({ year, month, day }));

  function onChangeMonth(nextMonth: string) {
    setYear(undefined);
    setMonth(nextMonth);

    const nextEncodedDate = encodeDate({ year, month: nextMonth, day });

    setEncodedOpeningHours(nextEncodedDate);

    onChange?.(nextEncodedDate);
  }

  function onChangeDay(nextDay: string) {
    setYear(undefined);
    setDay(nextDay);

    const nextEncodedDate = encodeDate({ year, month, day: nextDay });

    setEncodedOpeningHours(nextEncodedDate);

    onChange?.(nextEncodedDate);
  }

  const daysInMonth = (() => {
    if (!month) {
      return 31;
    }

    const monthDate = new Date();
    monthDate.setMonth(Number(month) - 1);

    // Special case for February: since we don't know the year, we assume it's a leap year
    if (monthDate.getMonth() === 1) {
      return 29;
    }

    return getDaysInMonth(monthDate);
  })();

  const days = makeNumberArray(daysInMonth).map((day) => ({
    label: `${day}`,
    value: `${day}`.padStart(2, '0'),
  }));

  return (
    <Fieldset hint={hint} label={label} required={required}>
      <OptionsWrapper>
        <MonthFieldWrapper>
          <SelectField
            disabled={disabled}
            form="_private"
            hideOptional
            label="Month"
            name={`${name}[month]`}
            onChange={onChangeMonth}
            options={months}
            required={required}
            value={month}
          />
        </MonthFieldWrapper>
        <SelectField
          disabled={disabled}
          form="_private"
          hideOptional
          label="Day"
          name={`${name}[day]`}
          onChange={onChangeDay}
          options={days}
          required={required}
          value={day}
        />
      </OptionsWrapper>
      <input type="hidden" name={name} value={encodedOpeningHours} />
    </Fieldset>
  );
}
