import { Link, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { HeaderAndFormWrapper, PageWrapper, Main, FormWrapper } from '../login/index.styles';

import Heading from '../../heading';
import Header from '../../header';
import Footer from '../../footer';

import RegisterWithDetailsStepRegister from './register';
import RegisterWithDetailsStepDetails from './details';

export default function RegisterWithDetailsPage() {
  const location = useLocation();

  return (
    <PageWrapper>
      <HeaderAndFormWrapper>
        <Header />
        <FormWrapper>
          <Main>
            <Heading align="center">Register</Heading>
            <Routes>
              <Route path="register" element={<RegisterWithDetailsStepRegister />} />
              <Route path="details" element={<RegisterWithDetailsStepDetails />} />
              <Route path="*" element={<Navigate to="register" state={location.state} />} />
            </Routes>
            <p>
              <T>Already have an account?</T>{' '}
              <Link to="/login" state={location.state}>
                <T>Login</T>
              </Link>
              .
            </p>
          </Main>
        </FormWrapper>
      </HeaderAndFormWrapper>
      <Footer />
    </PageWrapper>
  );
}
