import { Routes, Route, Navigate, resolvePath, useLocation } from 'react-router-dom';

import PlacePage from './place';

export default function PlacesPage() {
  const location = useLocation();

  return (
    <Routes>
      <Route path=":place_id/*" element={<PlacePage />} />
      <Route path="*" element={<Navigate to={resolvePath('..', location.pathname)} />} />
    </Routes>
  );
}
