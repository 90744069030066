import { toShortId } from '@rewardopl/utils/short_id';

import Barcode from './barcode';

import { Wrapper, CodeWrapper, ShortId } from './code.styles';

type CodeProps = {
  color?: string;
  displayShortId?: boolean;
  id: string;
};

export default function Code({ color, displayShortId = true, id }: CodeProps) {
  const shortId = toShortId(id);

  return (
    <Wrapper>
      <CodeWrapper>
        <Barcode fgColor={color} value={shortId} />
        {displayShortId ? <ShortId>{shortId}</ShortId> : null}
      </CodeWrapper>
    </Wrapper>
  );
}
