import { useAtomValue, useSetAtom } from 'jotai';
import T from '@wojtekmaj/react-t';

import Button from '@rewardopl/react-ui/button';
import Dialog from '@rewardopl/react-ui/dialog';

import { post } from '@rewardopl/utils/network';

import { ContentWrapper, ButtonWrapper } from './dialog.styles';

import MessageContent from '../inbox/message/message_content';

import { currentUserIdState, vendorMessageQuery } from '../../../../store';

import type { Message } from '@rewardopl/types';

type UrgentMessagesMonitorDialogProps = {
  onDismiss: () => void;
  message: Message;
};

export default function UrgentMessagesMonitorDialog({
  onDismiss,
  message,
}: UrgentMessagesMonitorDialogProps) {
  const { _id: messageId } = message;

  const currentUserId = useAtomValue(currentUserIdState);
  const setMessage = useSetAtom(vendorMessageQuery(messageId));

  async function closeDialog() {
    const response = await post(`/api/users/${currentUserId}/messages/${messageId}/read`);

    setMessage(response as Message);

    onDismiss();
  }

  return (
    <Dialog isOpen={true} onDismiss={closeDialog} title="Message">
      <ContentWrapper>
        <MessageContent messageId={messageId} />
        <ButtonWrapper>
          <Button className="primary" onClick={closeDialog}>
            <T>Close</T>
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Dialog>
  );
}
