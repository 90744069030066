import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;

  @media (screen-spanning: single-fold-vertical) {
    width: env(fold-left);
  }

  @media (horizontal-viewport-segments: 2) {
    width: env(viewport-segment-right 0 0);
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  width: 100%;
  margin: -100px 0 -90px;
  overflow: hidden;
`;

const shouldForwardProp = () => true;

export const Image = styled('img').withConfig({ shouldForwardProp })`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (min-width: 769px) {
    object-fit: contain;
  }
`;

export const Text = styled.p`
  font: inherit;
  font-size: 28px;
  font-weight: 500;
  margin: var(--gap);
  margin-bottom: 0;
  text-align: center;
`;
