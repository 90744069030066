import styled from 'styled-components';

export const Form = styled.form``;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: var(--gap);
  margin: 40px 0;
`;

export const Indent = styled.div`
  margin-left: 42px;
`;

export const Line = styled.div`
  height: 1px;
  background-color: var(--secondary-dark);
`;

export const Small = styled.p`
  margin: 16px 0;
  font-size: 10px;
  line-height: 15px;
  color: var(--text-light);
`;
