import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Intro = styled.p`
  margin: 0;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0 14px;
  gap: var(--gap);
`;

export const OrWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const OrLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: var(--stroke);
`;

export const OrText = styled.span`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;
