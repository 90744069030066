import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: var(--gap) 16px;

  @media screen and (min-width: 769px) {
    padding: var(--gap);
  }
`;

export const Description = styled.p`
  line-height: 1.5;
  white-space: pre-wrap;
`;
