import { alpha } from '@wojtekmaj/color-utils';

import colors from './colors';

const shadow = '#a16b46';
const secondary = '#05828a';

const shadows = {
  'shadow-1': `0px 5px 10px ${alpha(shadow, 0.1)}`,
  'shadow-1-reverse': `0px -5px 10px ${alpha(shadow, 0.1)}`,
  'shadow-2': `0px 10px 20px ${alpha(shadow, 0.125)}`,
  'shadow-2-reverse': `0px -10px 20px ${alpha(shadow, 0.125)}`,
  'shadow-3': `0px 20px 40px ${alpha(shadow, 0.125)}`,
  'shadow-3-reverse': `0px -20px 40px ${alpha(shadow, 0.125)}`,
  'primary-1': `0px 2px 2px ${alpha(colors.primary, 0.0625)}, 0px 5px 10px ${alpha(
    colors.primary,
    0.125,
  )}`,
  'primary-2': `0px 2px 3px ${alpha(colors.primary, 0.0625)}, 0px 10px 20px ${alpha(
    colors.primary,
    0.175,
  )}`,
  'primary-3': `0px 3px 6px ${alpha(colors.primary, 0.0625)}, 0px 20px 40px ${alpha(
    colors.primary,
    0.175,
  )}`,
  'secondary-1': `0px 5px 10px ${alpha(colors.secondary, 0.125)}`,
  'secondary-2': `0px 10px 20px ${alpha(secondary, 0.175)}`,
  'secondary-3': `0px 20px 40px ${alpha(secondary, 0.175)}`,
} as const;

export default shadows;
