import styled from 'styled-components';

import logoPlaceholder from '../../static/logo-placeholder.svg';

const logoPlaceholderUrl = `url("${logoPlaceholder}")`;

export const Logo = styled.img<{ borderWidth: number }>`
  display: block;
  background-color: var(--white);
  background-image: ${logoPlaceholderUrl};
  background-size: 100%;
  border: ${(props) => props.borderWidth}px solid var(--white);
  border-radius: var(--border-radius-round);
  overflow: hidden;
  color: transparent;
  text-indent: -999px;
`;
