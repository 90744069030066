import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';

import { post } from '@rewardopl/utils/network';

import {
  Wrapper,
  ScrollContainer,
  ContentWrapper,
  Heading,
  Description,
  Footer,
  ButtonWrapper,
} from './index.styles';

import {
  currentUserIdState,
  vendorBusinessState,
  vendorCardState,
  vendorCardSubscriptionState,
} from '../../../../../../store';

import type { CardSubscription } from '@rewardopl/types';

export default function AcceptTermsAndConditionsPage() {
  const navigate = useNavigate();
  const currentUserId = useAtomValue(currentUserIdState);
  const [cardSubscription, setCardSubscription] = useAtom(vendorCardSubscriptionState);
  const acceptTermsErrorString = useTranslation('Failed to accept Terms and Conditions');

  const { _id: cardSubscriptionId, terms_accepted: termsAccepted } = cardSubscription;

  const card = useAtomValue(vendorCardState);
  const business = useAtomValue(vendorBusinessState);

  if (termsAccepted) {
    return <Navigate to=".." />;
  }

  const action = `/api/users/${currentUserId}/card_subscriptions/${cardSubscriptionId}/accept_terms`;

  async function onAccept() {
    try {
      const response = (await post(action)) as CardSubscription;

      setCardSubscription(response);
    } catch {
      toast.error(acceptTermsErrorString);
    }

    navigate('../');
  }

  return (
    <Wrapper>
      <ScrollContainer>
        <ContentWrapper>
          <Heading>
            <T cardName={business.name}>{'{cardName} Terms and Conditions'}</T>
          </Heading>
          <Description>{card.terms_and_conditions}</Description>
        </ContentWrapper>
      </ScrollContainer>
      <Footer>
        <ButtonWrapper>
          <Button className="primary" onClick={onAccept} type="button">
            <T>Accept</T>
          </Button>
        </ButtonWrapper>
      </Footer>
    </Wrapper>
  );
}
