import styled, { css } from 'styled-components';

import { yourCodeButton } from '@rewardopl/styles/z-index';

import Section from '../../section.styles';

export const Button = styled.button`
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 67px + 12px);
  ${() =>
    navigator.standalone &&
    css`
      bottom: calc(15px + 67px + 12px);
      bottom: calc(max(15px, env(safe-area-inset-bottom)) + 67px + 12px);
    `}
  right: 12px;
  z-index: ${yourCodeButton};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: var(--border-radius-round);
  background-color: var(--primary);
  box-shadow: var(--shadow-1);
  color: var(--icon-reverse);

  @media screen and (min-width: 769px) {
    bottom: var(--gap);
    right: var(--gap);
  }
`;

export const Text = styled.p`
  margin: 0;
`;

export const CodeWrapper = styled(Section)`
  margin: 16px 12px;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  max-width: 140px;
  margin-left: var(--gap);
`;

export const ShortId = styled.p`
  margin-top: 8px;
  color: var(--white);
  font-weight: 700;
`;
