import styled from 'styled-components';

import { Wrapper as FieldWrapper, Optional, Hint } from '@rewardopl/react-ui/field/index.styles';

export const Wrapper = styled.fieldset`
  margin: 0;
  margin-bottom: var(--gap);
  padding: 0;
  border: 0;

  ${FieldWrapper} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Label = styled.legend`
  padding: 0;
  margin-bottom: 8px;
`;

export { Optional, Hint };
