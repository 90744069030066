import T from '@wojtekmaj/react-t';

import {
  Wrapper,
  BackButton,
  Title as HeaderWithButtonTitle,
  ExtraButton,
} from './header_with_button.styles';

import Title from '../../title';

type HeaderWithButtonProps = {
  children?: string;
  extraButton?: React.ReactNode;
};

export default function HeaderWithButton({ children, extraButton }: HeaderWithButtonProps) {
  return (
    <>
      <Title>{children}</Title>
      <Wrapper>
        <BackButton />
        <HeaderWithButtonTitle>
          <T>{children}</T>
        </HeaderWithButtonTitle>
        {extraButton ? <ExtraButton>{extraButton}</ExtraButton> : null}
      </Wrapper>
    </>
  );
}
