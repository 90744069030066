import { lazy, Suspense, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import Dialog from '@rewardopl/react-ui/dialog';

import LoadingText from './loading_text';

const PrivacyPolicy = lazy(() => import('./privacy_policy'));

export default function PrivacyPolicyLink() {
  const location = useLocation();
  const navigate = useNavigate();

  const close = useCallback(() => {
    navigate(
      {
        ...location,
        hash: '',
      },
      { state: location.state },
    );
  }, [location, navigate]);

  return (
    <>
      <Link to="#privacy-policy">
        <T>Privacy Policy</T>
      </Link>
      <Dialog isOpen={location.hash === '#privacy-policy'} onDismiss={close} title="Privacy Policy">
        <Suspense fallback={<LoadingText />}>
          <PrivacyPolicy />
        </Suspense>
      </Dialog>
    </>
  );
}
