import { useState } from 'react';

import CheckboxField from '@rewardopl/react-ui/checkbox_field';

import { OptionsWrapper } from './checkbox_group.styles';

type CheckboxGroupProps<T extends string> = {
  name: string;
  options: {
    label: string;
    value: T;
  }[];
};

export default function CheckboxGroup<T extends string>({ name, options }: CheckboxGroupProps<T>) {
  const [values, setValues] = useState<T[]>([]);

  function onChange(nextValue: T) {
    const nextValues = values.includes(nextValue)
      ? values.filter((value) => value !== nextValue)
      : [...values, nextValue];

    setValues(nextValues);
  }

  const hasAtLeastOneValue = values.length > 0;

  return (
    <OptionsWrapper>
      {options.map((option) => (
        <CheckboxField
          key={option.value}
          hideOptional
          label={option.label}
          name={name}
          onChange={() => onChange(option.value)}
          required={!hasAtLeastOneValue}
          value={option.value}
        />
      ))}
    </OptionsWrapper>
  );
}
