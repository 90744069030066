import T from '@wojtekmaj/react-t';
import { getOpeningHours } from '@wojtekmaj/opening-hours-utils';

import { formatTime, withLocale } from './date';

import { locale } from '../i18n/i18n';

import {
  COUNTRIES,
  COUNTRY_CALLING_CODES,
  COUNTRY_CODES,
  COUNTRY_NAMES,
  MOBILE_PHONE_PREFIXES,
  PHONE_FORMATS,
  WEEKDAY_ABBRS,
  WEEKDAY_NAMES,
} from '../constants';

import type { Address } from '@rewardopl/types/helpers/address';

export function formatAddress(addressObject?: Address | null): React.ReactNode {
  if (!addressObject) {
    return null;
  }

  const { address, postcode, city, country = 'PL' } = addressObject;

  const countryCode = (Object.keys(COUNTRY_CODES) as (keyof typeof COUNTRY_CODES)[]).find(
    (key) => COUNTRY_CODES[key] === country,
  );

  if (!countryCode) {
    throw new Error('Invalid country code');
  }

  const countryName = <T>{COUNTRY_NAMES[countryCode]}</T>;

  switch (country) {
    case 'UK':
      return (
        <>
          {address}
          <br />
          {city}
          <br />
          {postcode}
          <br />
          {countryName}
        </>
      );
    case 'DE':
    case 'PL':
    default:
      return (
        <>
          {address}
          <br />
          {postcode} {city}
          <br />
          {countryName}
        </>
      );
  }
}

function formatCouponAvailabilityItem(
  {
    from: hoursFrom,
    to: hoursTo,
  }: {
    from: string;
    to?: string | null;
  },
  index: number,
): React.ReactNode {
  if (hoursFrom === '00:00' && hoursTo === '24:00') {
    return <T key={index}>All day</T>;
  }

  if (!hoursTo) {
    return `${formatTime(hoursFrom)} -`;
  }

  return `${formatTime(hoursFrom)} - ${formatTime(hoursTo)}`;
}

function join<T>(arr: T[], joiner = ','): (T | string)[] {
  return arr.reduce<(T | string)[]>((previousValue, value, index) => {
    if (index) {
      previousValue.push(joiner);
    }

    previousValue.push(value);

    return previousValue;
  }, []);
}

export function formatCouponAvailability(openingHoursString?: string | null): React.ReactNode {
  if (!openingHoursString) {
    return null;
  }

  const openingHoursArray = getOpeningHours(openingHoursString);

  if (!openingHoursArray || !openingHoursArray.length) {
    return null;
  }

  function findKey(value: string): keyof typeof WEEKDAY_ABBRS {
    const result = (Object.keys(WEEKDAY_ABBRS) as (keyof typeof WEEKDAY_ABBRS)[]).find(
      (key) => WEEKDAY_ABBRS[key] === value,
    );

    if (!result) {
      throw new Error('Invalid weekday abbreviation');
    }

    return result;
  }

  return (
    <ul style={{ listStyle: 'none' }}>
      {openingHoursArray.map(({ from, to, hours }, index) => {
        const fromKey = findKey(from);
        const toKey = findKey(to);

        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: index is stable here
          <li key={index}>
            <T>{WEEKDAY_NAMES[fromKey]}</T>
            {fromKey === toKey ? null : (
              <>
                {' - '}
                <T>{WEEKDAY_NAMES[toKey]}</T>
              </>
            )}
            : {join(hours.map(formatCouponAvailabilityItem), '; ')}
          </li>
        );
      })}
    </ul>
  );
}

function commonFormatDistance(
  distanceInMeters?: number | null,
  options: { locale?: string } = {},
): string | null {
  if (distanceInMeters === null || distanceInMeters === undefined) {
    return null;
  }

  if (distanceInMeters > 1000) {
    return new Intl.NumberFormat(options.locale, {
      style: 'unit',
      unit: 'kilometer',
      maximumFractionDigits: 1,
    }).format(distanceInMeters / 1000);
  }

  return new Intl.NumberFormat(options.locale, {
    style: 'unit',
    unit: 'meter',
    maximumFractionDigits: 0,
  }).format(distanceInMeters);
}

export const formatDistance = withLocale(commonFormatDistance);

export function formatNumber(string?: number | null, maximumFractionDigits = 0) {
  if (string === null || string === undefined) {
    return '';
  }

  return new Intl.NumberFormat(locale, { maximumFractionDigits }).format(string);
}

function formatOpeningHour(
  {
    from: hoursFrom,
    to: hoursTo,
  }: {
    from: string;
    to?: string | null;
  },
  index: number,
): React.ReactNode {
  if (hoursFrom === '00:00' && hoursTo === '24:00') {
    return <T key={index}>Open 24h</T>;
  }

  if (!hoursTo) {
    return `${formatTime(hoursFrom)} -`;
  }

  return `${formatTime(hoursFrom)} - ${formatTime(hoursTo)}`;
}

/**
 * Formats opening hours string into a list of opening hours.
 *
 * @param {string} openingHoursString Opening hours string in OpenStreetMap format
 * @returns {React.ReactNode} List of opening hours
 */
export function formatOpeningHours(openingHoursString?: string | null): React.ReactNode {
  if (!openingHoursString) {
    return null;
  }

  const openingHoursArray = getOpeningHours(openingHoursString);

  if (!openingHoursArray || !openingHoursArray.length) {
    return null;
  }

  function findKey(value: string): keyof typeof WEEKDAY_ABBRS {
    const result = (Object.keys(WEEKDAY_ABBRS) as (keyof typeof WEEKDAY_ABBRS)[]).find(
      (key) => WEEKDAY_ABBRS[key] === value,
    );

    if (!result) {
      throw new Error('Invalid weekday abbreviation');
    }

    return result;
  }

  return (
    <ul style={{ listStyle: 'none' }}>
      {openingHoursArray.map(({ from, to, hours }, index) => {
        const fromKey = findKey(from);
        const toKey = findKey(to);

        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: index is stable here
          <li key={index}>
            <T>{WEEKDAY_NAMES[fromKey]}</T>
            {fromKey === toKey ? null : (
              <>
                {' - '}
                <T>{WEEKDAY_NAMES[toKey]}</T>
              </>
            )}
            : {join(hours.map(formatOpeningHour), '; ')}
          </li>
        );
      })}
    </ul>
  );
}

function applyNumberFormat(string: string, format?: string | null): string {
  if (!string || !format) {
    return string;
  }

  const stringNumbersOnly = string.replace(/\D/g, '');

  let i = 0;

  return format
    .split('#')
    .map((el) => {
      const char = stringNumbersOnly[i++];
      return char ? el + char : '';
    })
    .join('');
}

export function formatPhone(
  phone?: string | null,
  props?: React.ComponentProps<'a'>,
): React.ReactNode {
  if (!phone) {
    return null;
  }

  let phoneNumbersOnly = phone.replace(/[^\d+]/g, '');

  if (!phoneNumbersOnly.startsWith('+')) {
    phoneNumbersOnly = `${COUNTRY_CALLING_CODES[COUNTRIES.POLAND]}${phoneNumbersOnly}`;
  }

  const formattedPhone = (() => {
    const countryName = (() => {
      const [result] =
        (
          Object.entries(COUNTRY_CALLING_CODES) as [keyof typeof COUNTRY_CALLING_CODES, string][]
        ).find(([, value]) => phoneNumbersOnly?.startsWith(value)) || [];

      return result;
    })();

    if (countryName) {
      const countryCallingCode = COUNTRY_CALLING_CODES[countryName];
      const phoneWithoutCode = phoneNumbersOnly.slice(countryCallingCode.length);

      const isMobile = MOBILE_PHONE_PREFIXES[countryName].some((value) =>
        phoneWithoutCode.startsWith(value),
      );

      return `${countryCallingCode} ${applyNumberFormat(
        phoneWithoutCode,
        PHONE_FORMATS[countryName][isMobile ? 'MOBILE' : 'LANDLINE'],
      )}`;
    }

    return phoneNumbersOnly;
  })();

  return (
    <a {...props} href={`tel:${phone}`}>
      {formattedPhone}
    </a>
  );
}

export function formatPrice(price?: number | null, currency = 'PLN'): string {
  if (price === null || price === undefined) {
    return '';
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(price);
}

export function formatWebsite(
  website: string | null,
  props?: React.ComponentProps<'a'>,
): React.ReactNode {
  if (!website) {
    return '';
  }

  let host: string;
  let pathname: string;
  try {
    ({ host, pathname } = new URL(website));
  } catch {
    return website;
  }

  return (
    <a {...props} href={website} rel="noopener noreferrer" target="_blank">
      {host + (pathname !== '/' ? pathname : '')}
    </a>
  );
}

const pattern = /https?:\/\/[^\s]*/g;

export function addLinks(text?: string | null): React.ReactNode {
  if (!text) {
    return text;
  }

  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);

  if (!matches) {
    return text;
  }

  return splitText.reduce<React.ReactNode[]>((arr, element, index) => {
    const match = matches[index];

    arr.push(element);

    if (match) {
      arr.push(formatWebsite(match, { key: `${index}` }));
    }

    return arr;
  }, []);
}
