import T from '@wojtekmaj/react-t';

import { Wrapper, Label, Optional, Hint } from './fieldset.styles';

type FieldsetProps = {
  children?: React.ReactNode;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  required?: boolean;
};

export default function Fieldset({ children, hint, label, required = true }: FieldsetProps) {
  return (
    <Wrapper>
      {label ? (
        <Label>
          {typeof label === 'string' ? <T>{label}</T> : label}
          {required ? null : (
            <Optional>
              {' '}
              <T>Optional</T>
            </Optional>
          )}
        </Label>
      ) : null}
      {children}
      {hint ? <Hint>{typeof hint === 'string' ? <T>{hint}</T> : hint}</Hint> : null}
    </Wrapper>
  );
}
