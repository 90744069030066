import T from '@wojtekmaj/react-t';

import { Wrapper, ImageContainer, Image, Text } from './slide.styles';

type SlideProps = {
  fetchPriority?: 'high' | 'low' | 'auto';
  image: string;
  image2?: string;
  imageWebp?: string;
  imageWebp2?: string;
  imageAvif?: string;
  imageAvif2?: string;
  text: string;
};

export default function Slide({
  fetchPriority,
  image,
  image2,
  imageWebp,
  imageWebp2,
  imageAvif,
  imageAvif2,
  text,
}: SlideProps) {
  return (
    <Wrapper>
      <ImageContainer>
        <picture>
          <source srcSet={`${imageAvif}, ${imageAvif2} 2x`} type="image/avif" />
          <source srcSet={`${imageWebp}, ${imageWebp2} 2x`} type="image/webp" />
          <source srcSet={`${image}, ${image2} 2x`} type="image/png" />
          <Image alt="" src={image} width={375} height={404} fetchPriority={fetchPriority} />
        </picture>
      </ImageContainer>
      <Text>
        <T>{text}</T>
      </Text>
    </Wrapper>
  );
}
