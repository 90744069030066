import { Link, useLocation } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import InputField from '@rewardopl/react-ui/input_field';

import { get, post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from './form.styles';

import { cardSubscriptionsState, maybeCurrentUserState } from '../../../../../store';

import { VENDOR_CARD_ID } from '../../../../../constants';

import type { CardSubscription, User } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

export default function LoginTwoStepEmailStepPasswordForm() {
  const location = useLocation();
  const setCurrentUser = useSetAtom(maybeCurrentUserState);
  const setCardSubscriptions = useSetAtom(cardSubscriptionsState);
  const loginErrorString = useTranslation('Failed to log in');
  const invalidUsernameOrPasswordString = useTranslation('Invalid username or password');

  const { email } = (location.state || {}) as { email?: string };

  const action = '/api/users/login';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    try {
      const user = (await post(action, {
        body: new FormData(form),
      })) as User;

      const cardSubscriptions = (await get(
        `/api/users/${user._id}/card_subscriptions`,
      )) as CardSubscription[];

      const isUserSubscribed = cardSubscriptions.some(
        (cardSubscription) => cardSubscription.card_id === VENDOR_CARD_ID,
      );

      let response: CardSubscription | undefined;
      if (!isUserSubscribed) {
        response = (await post(`/api/users/${user._id}/card_subscriptions`, {
          body: { card_id: VENDOR_CARD_ID },
        })) as CardSubscription;
      }

      setCurrentUser(user);
      setCardSubscriptions(response ? [...cardSubscriptions, response] : cardSubscriptions);
    } catch (error) {
      if (error instanceof Error && error.message === 'Invalid username or password') {
        toast.error(invalidUsernameOrPasswordString);
        return;
      }

      toast.error(loginErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <InputField
        autoComplete="username"
        value={email}
        label="Email"
        maxLength={320}
        name="email"
        placeholder="email@example.com"
        type="email"
      />
      <InputField
        autoComplete="current-password"
        autoFocus
        label="Password"
        name="password"
        placeholder="••••••••"
        type="password"
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Login</T>
        </AsyncButton>
      </ButtonWrapper>
      <Link to="/password_reset">
        <T>Forgot password?</T>
      </Link>
    </Form>
  );
}
