import { Wrapper } from './page_wrapper.styles';

type PageWrapperProps = {
  children: React.ReactNode;
  withPadding?: boolean;
};

export default function PageWrapper({ children, withPadding = true }: PageWrapperProps) {
  return <Wrapper withPadding={withPadding}>{children}</Wrapper>;
}
