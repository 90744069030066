import {
  HeaderWrapper,
  ImageWrapper,
  Image,
  ContentWrapper,
  Name,
  Description,
  ProductLabels,
  LikeWrapper,
} from './content.styles';

import ProductLikeButton from '../../../../../../../common/product_like_button';
import LikeCount from '../../../../../../../common/like_count';
import ProductLabel from '../../../../../../../common/product_label';

import type { Product, ProductProduct } from '@rewardopl/types';

function getIsProductProduct(product: Product): product is ProductProduct {
  return product.type === 'product';
}

type ContentProps = {
  product: Product;
  setProduct: (product: Product) => void;
};

export default function Content({ product, setProduct }: ContentProps) {
  const isProductProduct = getIsProductProduct(product);

  const description = product.description?.trim();

  return (
    <>
      <HeaderWrapper>
        {product.picture ? (
          <ImageWrapper>
            <Image
              alt={product.name}
              src={`/api/uploads/${product.picture}?w=${112 * devicePixelRatio}`}
              width={112}
            />
          </ImageWrapper>
        ) : null}
        <ContentWrapper>
          <Name>{product.name}</Name>
          {description ? <Description>{description}</Description> : null}
          <ProductLabels>
            {isProductProduct ? (
              <>
                {product.is_vegan ? <ProductLabel variant="vegan" /> : null}
                {product.is_vegetarian ? <ProductLabel variant="vegetarian" /> : null}
                {product.contains_alcohol ? <ProductLabel variant="contains_alcohol" /> : null}
                {product.is_alcohol_free ? <ProductLabel variant="alcohol_free" /> : null}
                {product.is_gluten_free ? <ProductLabel variant="gluten_free" /> : null}
                {product.is_lactose_free ? <ProductLabel variant="lactose_free" /> : null}
                {product.is_sugar_free ? <ProductLabel variant="sugar_free" /> : null}
              </>
            ) : null}
            {product.tags?.map((tag) => (
              <ProductLabel key={tag}>{tag}</ProductLabel>
            ))}
          </ProductLabels>
        </ContentWrapper>
      </HeaderWrapper>
      <LikeWrapper>
        <ProductLikeButton product={product} setProduct={setProduct} />
        <LikeCount count={product.like_count} />
      </LikeWrapper>
    </>
  );
}
