import { useAtomValue } from 'jotai';

import Item from './item';

import { placeProductsQuery } from '../../../../../../../../store';

import type { Place } from '@rewardopl/types';

type ItemsProps = {
  placeId: Place['_id'];
};

export default function Items({ placeId }: ItemsProps) {
  const products = useAtomValue(placeProductsQuery(placeId));

  return products.map((product) => (
    <Item key={product._id} placeId={placeId} productId={product._id} />
  ));
}
