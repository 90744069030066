import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ withPadding?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  gap: var(--gap);

  ${(props) =>
    props.withPadding &&
    css`
      padding: var(--gap) 12px;

      @media screen and (min-width: 769px) {
        padding: var(--gap);
      }
    `}

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
  }
`;
