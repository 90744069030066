import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--info-light);
  margin: -1px 0 0;
  padding: 15px;
  font-size: 16px;
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: inherit;
    font-weight: 700;
  }

  @media screen and (min-width: 769px) {
    margin: 0 -15px var(--gap);
  }
`;
