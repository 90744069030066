import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  width: 112px;
  height: 112px;
  border-radius: var(--border-radius);
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled.h3`
  display: inline-block;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  margin: 4px 0;
  color: var(--text-light);
  white-space: pre-wrap;
`;

export const ProductLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

export const LikeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap);
  padding: 16px;
`;
