import styled from 'styled-components';

import { visuallyHidden } from '@rewardopl/react-ui/visually_hidden/index.styles';

export const Wrapper = styled.div``;

export const Image = styled.img`
  width: 128px;
  height: 128px;
  margin-bottom: var(--gap);
`;

export const Description = styled.p`
  font-size: 18px;
`;

export const Question = styled.p``;

export const ThumbsWrapper = styled.div`
  margin: var(--gap) 0;
  display: flex;
  justify-content: center;
  gap: var(--gap);
`;

export const Input = styled.input`
  ${visuallyHidden}
`;

export const Label = styled.label`
  img,
  svg {
    transition: all ease-in-out 200ms;
  }

  ${Input}:checked + & {
    img,
    svg {
      scale: 1.2;
    }
  }
`;

export const Text = styled.span`
  ${visuallyHidden}
`;
