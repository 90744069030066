import styled from 'styled-components';

import { main } from '@rewardopl/styles/z-index';

export const Wrapper = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  margin-bottom: -24px;
  padding-bottom: 24px;
  background: var(--background);
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-color: var(--text) transparent;
  scrollbar-width: auto;
  position: relative;
  z-index: ${main};
  -webkit-overflow-scrolling: touch;

  &:has([data-dialog='true']) {
    overflow: unset;
    z-index: unset;
  }
`;

export const HeaderPlaceholder = styled.div`
  padding-top: env(safe-area-inset-top);
`;

export const Main = styled.main`
  flex-grow: 1;
  max-width: 100vw;
  position: relative;
  padding-right: env(safe-area-inset-right);
`;
