import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

import UrgentMessagesMonitorDialog from './dialog';

import { vendorUrgentMessagesState } from '../../../../store';

import type { Message } from '@rewardopl/types';

type UrgentMessagesMonitorProps = {
  closeDialogAndWaitForDestroyed: () => Promise<void>;
};

const MAX_MESSAGES = 3;

export default function UrgentMessagesMonitor({
  closeDialogAndWaitForDestroyed,
}: UrgentMessagesMonitorProps) {
  const urgentMessages = useAtomValue(vendorUrgentMessagesState);
  const [messageForDisplay, setMessageForDisplay] = useState<Message | null>(null);

  useEffect(() => {
    if (messageForDisplay) {
      return;
    }

    const nextMessageForDisplay = urgentMessages
      .slice(0, MAX_MESSAGES)
      .find((message) => !message.is_read);

    if (nextMessageForDisplay) {
      closeDialogAndWaitForDestroyed().then(() => {
        setMessageForDisplay(nextMessageForDisplay);
      });
    }
  }, [closeDialogAndWaitForDestroyed, messageForDisplay, urgentMessages]);

  if (!messageForDisplay) {
    return null;
  }

  function closeDialog() {
    setMessageForDisplay(null);
  }

  return <UrgentMessagesMonitorDialog onDismiss={closeDialog} message={messageForDisplay} />;
}
