import { useLocation } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import T from '@wojtekmaj/react-t';

import LinkButton from '@rewardopl/react-ui/link_button';

import { Wrapper, Intro, ButtonWrapper, OrLine, OrText, OrWrapper } from './index.styles';

import LoginWithAppleButton from './login_with_apple_button';
import LoginWithFacebookButton from './login_with_facebook_button';
import LoginWithGoogleButton from './login_with_google_button';

import { configLoginState, vendorCardLoadable } from '../../../../store';

export default function LoginMain() {
  const location = useLocation();
  const configLogin = useAtomValue(configLoginState);
  useAtomValue(vendorCardLoadable);

  const { state } = location;
  const { apple, google, facebook, email, two_step_email } = configLogin;

  return (
    <Wrapper>
      <Intro>
        <T>
          {'Register in the app and collect stamps to collect prizes at all our points in Poland.'}
        </T>
      </Intro>
      <ButtonWrapper>
        {email ? (
          <LinkButton className="primary" to="email" state={state}>
            <T>Login</T>
          </LinkButton>
        ) : null}
        {two_step_email ? (
          <LinkButton className="primary" to="two_step_email" state={state}>
            <T>Login</T>
          </LinkButton>
        ) : null}
        <LinkButton to="/register_with_details" state={state}>
          <T>Register</T>
        </LinkButton>
        <OrWrapper>
          <OrLine />
          <OrText>
            <T>or</T>
          </OrText>
          <OrLine />
        </OrWrapper>
        {apple ? <LoginWithAppleButton /> : null}
        {google ? <LoginWithGoogleButton /> : null}
        {facebook ? <LoginWithFacebookButton /> : null}
      </ButtonWrapper>
    </Wrapper>
  );
}
