import { Routes, Route, Navigate, resolvePath, useLocation } from 'react-router-dom';

import Transaction from './transaction';

export default function RewardReceivedPage() {
  const location = useLocation();

  return (
    <Routes>
      <Route path=":transaction_id/*" element={<Transaction />} />
      <Route path="*" element={<Navigate to={resolvePath('..', location.pathname)} />} />
    </Routes>
  );
}
