import RadioField from '@rewardopl/react-ui/radio_field';

import { OptionsWrapper } from './radio_group.styles';

type RadioGroupProps<T extends string> = {
  name: string;
  onChange?: (value: T) => void;
  options: {
    label: string;
    value: T;
  }[];
};

export default function RadioGroup<T extends string>({
  name,
  onChange,
  options,
}: RadioGroupProps<T>) {
  return (
    <OptionsWrapper>
      {options.map((option) => (
        <RadioField
          key={option.value}
          hideOptional
          label={option.label}
          name={name}
          onChange={onChange}
          required
          value={option.value}
        />
      ))}
    </OptionsWrapper>
  );
}
