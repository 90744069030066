import { Suspense, useRef } from 'react';

import { Wrapper } from './index.styles';

import Nav from './nav';
import Content from './content';
import YourCodeButton from './your_code_button';
import WebsocketHandler from './websocket_handler';
import TransactionsMonitor from './transactions_monitor';
import UrgentMessagesMonitor from './urgent_messages_monitor';

async function sleep(ms: number) {
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default function IndexPage() {
  const closeDialog = useRef<(() => void) | undefined>(undefined);
  const isDestroyed = useRef<boolean | undefined>(undefined);

  function onCreated() {
    isDestroyed.current = false;
  }

  function onDestroyed() {
    isDestroyed.current = true;
  }

  async function closeDialogAndWaitForDestroyed() {
    closeDialog.current?.();

    if (isDestroyed.current === false) {
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (isDestroyed.current) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }

    await sleep(500);
  }

  return (
    <Wrapper>
      <Nav />
      <Content />
      <YourCodeButton
        onCreated={onCreated}
        onDestroyed={onDestroyed}
        ref={(ref) => {
          if (!ref) {
            return;
          }

          closeDialog.current = ref.closeDialog;
        }}
      />
      <WebsocketHandler />
      <Suspense fallback={null}>
        <TransactionsMonitor closeDialogAndWaitForDestroyed={closeDialogAndWaitForDestroyed} />
      </Suspense>
      <Suspense fallback={null}>
        <UrgentMessagesMonitor closeDialogAndWaitForDestroyed={closeDialogAndWaitForDestroyed} />
      </Suspense>
    </Wrapper>
  );
}
